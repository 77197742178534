import dayjs from 'dayjs'
import type { RequestOptions, SearchOptions } from '@nuxtjs/algolia'
import type { SearchFilterOptions } from '~/composables/useSearchFilters'

const PRICE_MULTIPLIER = 100
const getISO2Locale = (locale: string): string => {
  const ISO2LocaleParts = locale.split('-')
  return ISO2LocaleParts[0]
}

const getEventListIndexName = (locale: string = 'de-de'): string => {
  return `events_${getISO2Locale(locale)}`
}

const getTopEventListIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}`
}

const getEventListSortByDateAscIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_created_at_asc`
}

const getEventListSortByDateDescIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_created_at_desc`
}

const getEventListSortByPriceAscIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_sort_by_price_asc`
}

const getAutocompleteQuerySuggestionsIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_query_suggestions`
}

const getTopGiftIdeasIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_top_gift_ideas`
}

const getCitiesIndexName = (): string => {
  return 'localities'
}

const getQuerySuggestionsIndex = (): string => {
  return `events_de_query_suggestions`
}

const getCategoriesIndexName = (locale: string): string => {
  return `categories_${getISO2Locale(locale)}`
}

const getBoxesIndexName = (locale: string): string => {
  return `products_${getISO2Locale(locale)}`
}

const getFederatedSearchIndexName = (locale: string): string => {
  return `events_${getISO2Locale(locale)}_federated_search`
}

const fnGetFacetFilters = (array: string[], facetName: string) =>
  array.map((f) => `${facetName}:${f}`)

const getCategoryFilters = (categories: string[], categorySlugs: string[]) => {
  if (categorySlugs && categorySlugs.length) {
    return categorySlugs.map((cslug) => `categories.slug:${cslug}`)
  }
  if (categories?.length > 0) {
    const categoriesArr = categories?.map(
      (category) => `categories.name:${category}`
    )
    return categoriesArr
    // return  `[${categoriesArr.join(',')}]`
  }

  return []
}

const parseFiltersToAlgoliaParams = (filters: Partial<SearchFilterOptions>) => {
  const result: string[] = []

  if (Number(filters.tags?.length) > 0) {
    result.push(`(tags.name: "${filters.tags?.[0]}")`)
  }

  const price = [
    filters.priceMin || filters.priceMin === 0
      ? `default_price.amount >= ${filters.priceMin * PRICE_MULTIPLIER}`
      : null,
    filters.priceMax
      ? `default_price.amount <= ${filters.priceMax * PRICE_MULTIPLIER}`
      : null,
  ]
    .filter((item) => {
      return typeof item !== 'undefined' && item !== '' && item !== null
    })
    .join(' AND ')
  if (price) result.push(price)
  if (filters.dates?.length) {
    const dates_ts = (filters.dates || []).map((d) => dayjs(d).unix())
    // If it's the same date, add one day to second date
    // Or if there's only one date, add one day to it
    if (dates_ts[0] === dates_ts[1] || (dates_ts[0] && !dates_ts[1])) {
      dates_ts[1] = dates_ts[0] + 24 * 60 * 60
    }
    result.push(
      [
        `dates_ts:${dates_ts.join(' TO ')}`,
        `dates_private_ts:${dates_ts.join(' TO ')}`,
      ].join(' OR ')
    )
  }

  if (filters.hasAvailableDates) {
    result.push('has_events:true OR has_direct_events:true')
  }

  /**
   * Show online and offline events together only when
   * we don't have `city` filter in URL
   * when `city` and `online` selected => show only online events
   */
  if (filters.city === undefined && !filters.online) {
    result.push('is_online:true OR is_online:false')
  } else {
    result.push(filters.online ? 'is_online:true' : 'is_online:false')
  }

  return result.map((f) => `(${f})`).join(' AND ')
}

const getAlgoliaSearchOptions = ({
  filters,
  rawOptions,
}: {
  filters: SearchFilterOptions
  rawOptions: Partial<SearchOptions & RequestOptions>
}): SearchOptions => {
  return {
    query: filters?.searchquery,
    facetFilters: [
      fnGetFacetFilters(
        filters.categories?.map((c) => c?.toString() || '') || [],
        'categories.name'
      ),
      fnGetFacetFilters([], 'categories.slug'),
      fnGetFacetFilters(filters.languages || [], 'languages'),
    ].filter((filters) => filters.length),
    filters: parseFiltersToAlgoliaParams({
      ...filters,
    }),
    ...rawOptions,
  }
}

export {
  getISO2Locale,
  getTopEventListIndexName,
  getEventListIndexName,
  getEventListSortByDateAscIndexName,
  getEventListSortByDateDescIndexName,
  getEventListSortByPriceAscIndexName,
  getAutocompleteQuerySuggestionsIndexName,
  getCitiesIndexName,
  getTopGiftIdeasIndexName,
  getQuerySuggestionsIndex,
  getCategoriesIndexName,
  getBoxesIndexName,
  getFederatedSearchIndexName,
  getAlgoliaSearchOptions,
}
